import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Welcome to my website</h1>
    <p>
      It's just been created (8th Feb 2020) so I guess there will be something more interesting right here very soon!
    </p>
  </Layout>
);

export default IndexPage;
